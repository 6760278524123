.box-slider-img-swipe {
  max-width: 1520px;
  margin: 0 auto;
  padding: 50px 0;

  @include rwd(md-up) {
    padding: 60px 70px;
  }

  @include rwd(lg-up) {
    padding: 50px 100px; 
  }

  @include rwd(xl-up) {
    padding: 50px 150px; 
  }

  .slick-track {
    display: flex;
    align-items: center;
    flex-flow: row;
  }

  .image,
  .image-swipe {
    img{
      width: 100%;
    }
  } 

  p {
    margin-bottom: 20px;
    font-size: 16px;
    padding: 0 24px;
    line-height: 1.5em;
  }

  .slider-img-swipe {
    opacity: 0;
    transition: opacity 1.5s;
    position: relative;

    &.slick-initialized {
      opacity: 1;
    }
  }

  .slide-wrap {
    position: relative;
    padding-bottom: 2%;
    overflow: hidden;
  
    .slide-content {
      margin-top: 13%;
      position: relative;
      overflow: hidden;
      padding-bottom: 77%;
      background: #fff;
    }

    &.slick-center{
      transform: scale(1.1);
      z-index: 99;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

     
        .swipe-up {
          display: block !important;
        }
        
    }   

    
  }

  .swipe-wrap {
    top: 73%;
    position: absolute;
    overflow: hidden;
    text-align: center;
    background: $flat-blue;
    height: 100%;
    width: 100%; 
    -moz-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
    -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
    transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;

    @include rwd(md-up) {
      top: 69%;
    }

    @include rwd(lg-up) {
      top: 70%;
    }
  
    @include rwd(xl-up) {
      top: 69%;
    }
    

    .swipe-up {
      color:#fff;
      font-size: 14px;
      text-transform: uppercase;
      position: absolute;
      top: 25px;
      width: 100%;
      text-align: center;
      left: 0;
      display: none;

      .open {
        display: none;
      }

      .icon img{
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }

    .swipe-down {
      color:#fff;
      font-size: 14px;
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      margin-top: 30px;


      .icon img{
        margin: 0 auto;
        margin-top: 10px;
      }
    }

    &.active {
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .text-wrap-swipe {
        order: 2;
      }

      .image-swipe {
        order: 3;
      }

      .swipe-up {
        top: auto;
        bottom: 27%;
        .open{
          display: block;
        }
        .close{
          display: none;
        }
        .icon img {
          transform: rotate(180deg);
        }
      }
    }

    .text-wrap-swipe {
      padding: 30px 20px;

    }

    .home & {
      background: $bg-swipe-home;
    }

    .speedwave &{
      background-color: $bg-swipe-wave;
    }
  
    .omnichef &{
      background-color: $bg-swipe-omni;
    }

    .icon-list-wrap {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .icon-wrap {
        margin: 0 10px;
        text-align: center;
        width: 20%;
        font-size: 9px;
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .icon-img {
          width: 100%;
          margin-bottom: 5px;
        }

        .icon-title {
          width: 100%;
        }

        img {
          max-width: 80px;
          width: 100%;
        }
      }
    }
  }

  .slick-arrow {
    border: none;
    opacity: 0;
    width: 30%;
    height: 100%;
    top:50%;
    z-index: 4;


    &.slick-next{
      right: 0;

      :before {
        content: "";
      }
     
    }

    &.slick-prev {
      left: 0;

      :before {
        content: "";
      }
      
    }

  }

  .slick-dots {
    bottom: -25px;
    left: 0;

    li {
      border: 1px solid $white;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: 0 10px;

      @include rwd(md) {
        height: 5px;
        width: 5px;
      }

      &.slick-active {
        background-color: $white;
      }
    }
    
  }

  .slide-top {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .image  {
    width: 100%;
    flex-grow: 1;

    img {
      width: 100%;
    }
  }

  .text-wrap {
    padding: 30px 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    min-height: 370px;
    

    @include rwd(sm-up) {
      
    }

    @include rwd(md-up) {
      
    }

  }

  h3 {
    width: 100%;
    margin-bottom: 30px;
    font-weight: 900;

    u {
      font-weight: 900;
    }
  }
  .caption {
    margin-bottom: 30px;
    
  }

  .cta-btn {
    margin: 0 auto;
    max-width: 160px;
  }

  
  
}
