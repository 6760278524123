.box-img-text-shadow {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;

  @include rwd(md-up) {
    //max-height: 85vh;
    //overflow: hidden;
  }
 
   &.img-right {
     .image {
      @include rwd(md-up) {
        order: 2;   
      }
     }
   }

   .image {
     position: relative;

     .shadow-overlay {
      position: absolute;
      bottom: 40px;
      height: 35%;
      width: 100%;
      background-image: linear-gradient(to top, #000 , transparent);
 
      @include rwd(md-up) {
        height: 100%;
        bottom: 0;
        width: 35%;
        right: 0;
        background-image: linear-gradient(to left, #000 , transparent);
     }

     .home & {
        background-image: linear-gradient(to top, #000, transparent);

        @include rwd(md-up) {
          background-image: linear-gradient(to left, #000, transparent);
        }
      }
    
      .speedwave &{
        background-image: linear-gradient(to top, #000, transparent);

        @include rwd(md-up) {
          background-image: linear-gradient(to left, #000, transparent);
        }
      }
    
      .omnichef &{
        background-image: linear-gradient(to top, #000 , transparent);

        @include rwd(md-up) {
          background-image: linear-gradient(to left, #000 , transparent);
        }
      }
     
    }
   }

  .image,
  .text {
    width: 100%;

    @include rwd(md-up) {
      width: 50%; 
      flex: 1 0 auto;   
    }
  }

  .image img {
    width: 100%;

    @include rwd(md-up) {
      width: auto;
      //height: 100%;
    }
  }

  .text-wrap {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin-top: -100px;
    position: relative;
    z-index: 4;
    text-align: center;
    background: #000;

    .logo-text {
      max-width: 180px;
      max-height: unset;
      margin-bottom: 40px;
    }

    @include rwd(md) {
      padding: 0 20px;  
    }

    @include rwd(md-up) {
      align-items: flex-start;
      //margin-left: -30px;
      padding: 60px 90px 60px 90px;
      margin-top: auto;
      text-align: left;
      height: calc(100% - 120px); 
     
    }

  }

  .text {
    height: auto;
    padding: 60px 0;
    background-color: #000;

    @include rwd(sm-up) {
      height: 100vw;
      padding: 0;
    }

    @include rwd(md-up) {
      height: auto;
    }

    .home & {
      background-color: #000;
    }

    .speedwave &{
      background-color: #000;
    }
  
    .omnichef &{
      background-color: #000;
    }

    // &.bg-black {
     
    // }
    // &.bg-black-blue {
    //   background-color: $bg-black-blue;
    // }
    // &.bg-black-darkblue {
    //   background-color: $bg-black-darkblue;
    // }
  }

  h3 {
    width: 100%;
    margin-bottom: 40px;
  }
  .caption {
    margin-bottom: 35px;
    @include rwd(md-up) {
      margin-bottom: 60px;
    }
  }

  p {
    font-size: 1.125rem;

    @include rwd(md-up) {
      font-size: 1.2rem;
    }
  }

  .cta-btn {
    margin: 0;
    max-width: 160px;
    margin-top: 30px;
  }
  
}
