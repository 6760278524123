body.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  padding: 0 !important;

    &.fade {
      transition: opacity .15s linear;
      .modal-dialog {
        transition: transform .3s ease-out;
        position: relative;
        width: auto;
        margin: .5rem;
        //pointer-events: none;

        &.modal-fullscreen {
          margin: 0;
          width: 100%;
         // height: calc(100% + 40px);
        }
      }
  }

  &.fade:not(.show) {
    opacity: 0;
  }

  &.show {
    .modal-dialog {
      transform: translate(0,0);
    }
  }

  
}

  .modal.show {
    overflow-x: hidden;
    overflow-y: auto;
  }


.modal-simply {
  .modal-dialog {


    @include rwd(md-up) {
      display: flex;
      align-items: center;
      min-height: calc(100% - (.5rem * 2));
      
  
      
  
    }
  
    .modal-content {
      max-width: 100%;
      height: 100vh;
      padding: 20px;
      
      @include rwd(md-up) {
        width: 100%;
        max-width: $max-mWidth;
        height: auto;
        margin: 0 auto;
      }
  
      .modal-wrap {
        border: 1px solid $white;
        position: relative;
        height: calc(100vh - 110px);
        padding: 30px;
  
        @include rwd(md-up) {
          height: auto;
        }
  
        .close {
          display: flex;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          position: absolute;
          top: -15px;
          right: -15px;
          font-size: 20px;
          font-weight: bold;
          align-items: center;
          justify-content: center;
        }
      }
    }
    
  }
}

.modal-advanced {
  .modal-dialog {
    text-align: center;

    @include rwd(md-up) {
      display: flex;
      align-items: center;
      min-height: calc(100% - (.5rem * 2));
  
      &::before {
        display: block; // IE10
        height: calc(100vh - (.5rem * 2));
        content: "";
      }
  
    }

    .progressbar {
      border: 1px solid $white;
      width: 85%;
      margin: 30px auto;
      height: 4px;
      position: relative;

      @include rwd(md-up) {
        max-width: 600px;
      }

      .bg-progress {
        height: 100%;
        background: $bg-white;
      }
    }
  
    .modal-content {
      width: 100%;
      min-height: 100vh;
      
      @include rwd(md-up) {
        width: 100%;
        //max-width: $max-mWidth;
        height: auto;
        margin: 0 auto;
      }
  
      .modal-wrap {
        //border: 1px solid $white;
        position: relative;
        //height: calc(100vh - 110px);
        padding: 30px;
        display: block;
  
        @include rwd(md-up) {
          height: auto;
        }
      }
    }  
  }
  .modal-close-wrap {
    width: 100%;
    padding: 10px 0;
    background-color: #23232f;
    position: fixed;
    top: 0;
    left: 0;
    text-align: right;
    z-index: 100;
    .close {
      margin-right: 20px;
    }
  }
}


.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: $black;

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: 0.5; }
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

#countryModal {
  position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    outline: 0;

  .modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    outline: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
    color: #020202;
  }

  .modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
  }

  .container-fluid {
    position: relative;
    padding-bottom: 80px;
 
  }

  .logo {
    width: 200px;
    height: auto;
    text-align: center;
    margin: 0 auto 40px;
  }

  .titolo {
    margin-bottom: 40px;
  }  

  .description {
    font-size: 1.25rem; 
    margin-bottom: 30px;
  }

  #btnGoTo {
    display: inline-block;
    max-width: 300px;
    margin: auto;
    background: #000; 
    color: #ffffff;
  }

  #btnCancel {
    text-decoration: underline; 
    position: absolute;
    display: block;
    bottom: 0;

  }

  .modal-header {
    display: -ms-flexbox;
    display: flex;
    align-items: flex-end;
    -ms-flex-pack: justify;
    justify-content: right;
    border-bottom: 0;
    padding: 0.5rem 1rem;
  }

  .modal-lg, .modal-xl {
    max-width: 800px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  @media (min-width: 576px) {
    .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
    }
  }
  
  @media (min-width: 576px){
    .modal-dialog {
      
      margin: 1.75rem auto;
    }
  }
  
  .modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  .modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
  }

  .button-primary {
    font-family: Raleway,sans-serif;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.92px;
    line-height: 24px;
    background-color: #fff;
    color: #020202;
    border: 1px solid #020202;
    width: 100%;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    text-align: center;
    padding: 10px;
    max-width: 300px;
  }

  #btnCancel {
    text-decoration: underline;
    display: block;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 30px;
  }

  .titolo{
    margin-bottom: 20px;
  }
  h3 {
      font-size: 30px;
      font-weight: 400;
      line-height: 38px;
  }

  body, p {
    font-size: 12.5px;
    font-family: 'Mulish', sans-serif;
    font-weight: 300;
    letter-spacing: .32px;
    line-height: 2rem;

  }
  
}

