.box-slider {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;

  @include rwd(md) {
    margin: 60px 0;
  }

  .slider-img-text {
    opacity: 0;
    transition: opacity 0.5s;
    padding: 0 20px;

    @include rwd(md-up) {
      padding: 60px 70px;
    }

    @include rwd(lg-up) {
      padding: 50px 100px; 
    }
  
    @include rwd(xl-up) {
      padding: 50px 200px; 
    }

    &.slick-initialized {
      opacity: 1;
    }
  }
  .slide-wrap {
    
    @include rwd(md-up) { 
      display: flex !important;   
    }
  }
  .slick-track {
    @include rwd(md) {
      display: flex;
      background: $white;
    }
  }


  .slick-arrow.slider-arrow {
    border: 1px solid $brown;
    opacity: 0.6; 
    border-radius: 50%;
    width: 52px;
    height: 52px;
    font-family: 'nomospace';
    font-size: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &.slick-next{
      
      @include rwd(md-up) {
        right: 10px;
      }
  
      @include rwd(lg-up) {
        right: 35px;
      }
    
      @include rwd(xl-up) {
        right: 100px;
      }

      &:before {
        content: ">";
        font-family: monospace;
      }
    }

    &.slick-prev {
      @include rwd(md-up) {
        left: 10px;
      }
  
      @include rwd(lg-up) {
        left: 35px;
      }
    
      @include rwd(xl-up) {
        left: 100px;
      }
      &:before {
        content: "<";
        font-family: monospace;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .slick-dots {
    bottom: 0;
    left: 0;

    @include rwd(md) {
      bottom: -25px;
    }

    li {
      border: 1px solid $white;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: 0 10px;

      @include rwd(md) {
        height: 5px;
        width: 5px;
      }

      &.slick-active {
        background-color: $white;
      }
    }
    
  }
 
   &.img-right {
     .image {
      @include rwd(md-up) {
        order: 2;   
      }
     }
   }
  
  .image,
  .text {
    width: 100%;

    @include rwd(md-up) {
      width: 49%; 
      flex: 1 0 auto;   
    }
  }

  .image img {
    width: 100%;
  }

  .text-wrap {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: left;

    @include rwd(md-up) {
      padding: 0 55px;  
      padding-left: 90px;
    }

    p{
      color: $txt-grey;
    }

  }

  .text {
    height: auto;
    padding: 60px 0;
    max-height: 600px;

    @include rwd(sm-up) {
      height: 100vw;
      padding: 0;
    }

    @include rwd(md-up) {
      height: auto;
      max-height: none;
    }

    &.bg-black {
      background-color: $bg-black;
    }
    &.bg-black-blue {
      background-color: $bg-black-blue;
    }
    &.bg-black-darkblue {
      background-color: $bg-black-darkblue;
    }
  }

  h3 {
    width: 100%;
    margin-bottom: 40px;

    @include rwd(md-up) {
      margin-bottom: 5%;
    }
  
    @include rwd(xl-up) {
      margin-bottom: 40px;
    }
  }
  .caption {
    margin-bottom: 35px;

    @include rwd(md-up) {
      margin-bottom: 5%;
    }
  
    @include rwd(xl-up) {
      margin-bottom: 60px;
    }
  }

  .cta-btn {
    margin: 0 auto;
    max-width: 160px;
  }
  
}
