.box-slider-vertical {
  max-width: 1920px;
  margin: 0 auto;
  height: auto;
  position: relative;
  width: 100%;

  @include rwd(xmd-up) {
    width: auto;
    width: 100%;
    padding: 0 20px 30px 20px;
  }


  .slider-vertical-scroll {
    opacity: 0;
    transition: opacity 0.5s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.slick-initialized { 
      opacity: 1;
    }
  }
  .slide-wrap {
    
    @include rwd(xmd-up) {
      display: flex;   
    }
  }

  .slick-arrow.slider-arrow {
    border: 1px solid $brown;
    opacity: 0.6; 
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-family: 'nomospace';
    font-size: 30px;
    display: none;

    &.slick-next{
      right: -45px;
      &:before {
        content: ">";
      }
    }

    &.slick-prev {
      left: -45px;
      &:before {
        content: "<";
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .slick-dots {
    bottom: auto;
    width: auto;
    top: 40%;
    left: 20px;

    @include rwd(xmd) {
      display: none;   
    }

    li {
      border: 1px solid $white;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      display: block;
      margin-bottom: 20px; 

      @include rwd(md) {
        height: 5px;
        width: 5px;
      }

      &:only-child {
        display: none;
      }

      &.slick-active {
        background-color: $white;
      }
    }
    
  }
  .image img {
    height: auto;
    width: 100vw;
    margin: 0 auto;
    

    @include rwd(xmd-up) {
      max-height: 90vh; 
      width: auto;
      max-width: 100%; 
      padding-top: 20px;
    }
  }

  h3 {
    width: 100%;
    margin-bottom: 40px;
  }
  .caption {
    margin-bottom: 35px;
    @include rwd(xmd-up) {
      margin-bottom: 60px;
    }
  }

  .cta-btn {
    margin: 0;
    max-width: 160px;
  }

  .text {
    position: absolute;
    width: 60%;
    font-size: 2vw;
    line-height: 1.2;

    @include rwd(xmd-up) {
      width: 25%;
    }

    @include rwd(xmd-up) {
      font-size: 1vw;
    }

    .text-title{
      text-transform: uppercase; 
      font-weight: 900;
      font-size: 6vw;
      margin-bottom: 4px;
      
      @include rwd(xmd-up) {
        font-size: 1.6vw;
        margin-bottom: 5px;
      }

      u {
        font-weight: 900;
      }
    }
    .txt p{
      font-size: 4vw;
      line-height: 1.2;

      @include rwd(xmd-up) {
        font-size: 1vw;
      }
    }
  }

  .mobile-img {
    display: none;

    @include rwd(xmd) { 
      display: block;
    }
  }

  .desktop-img {
    display: block;

    @include rwd(xmd) {
      display: none;
    }
  }

  .text-0 {
    
    @include rwd(xmd) {
      background: #000;
      text-align: right;
      left: auto;
      right: 7%;
      bottom: 43%;
    }

  }

  .slide-scroll-2{
    .text-0 {
      @include rwd(xmd-up) {
        text-align: right;
        top: 40%;
        left: 5%;
      }
    }
  }

  .slide-scroll-3{
    .text-0 {
     
      @include rwd(xmd-up) {
        text-align: right;
        bottom: 15%;
        left: 5%;
      }      
    }
  }

  .slide-scroll-4{
    .text-0 {
      @include rwd(xmd-up) {
        text-align: left;
        bottom: 15%;
        right: 6%;
      }     
    }
  }

  .slide-scroll-5{
    .text-0 {
      
      @include rwd(xmd-up) {
        text-align: left;
        top: 40%;
        right: 5%;
      }   
    }
  }

  .slide-scroll-6{
    .text-0 {
      
      @include rwd(xmd-up) {
        width: 100%;
        top: 8%;
        margin: 0 auto;
        text-align: center;
      }   
    }
  }

  .slide-scroll-7{
    .text {
      width: 25%;
      font-size: 2vw;

      @include rwd(xmd-up) {
        font-size: 1vw;

      }

      .text-title {
        font-size: 2.2vw;
        font-weight: 900;
        @include rwd(xmd-up) {
          font-size: 1.6vw;
  
        }

        u{
          font-weight: 900;
        }
      }

      .txt p {
        font-size: 1.8vw;
        @include rwd(xmd-up) {
          font-size: 1vw; 
  
        }
      }
    }
    .text-0 {
      text-align: right;
      top: 40%;
      left: 5%;

      @include rwd(xmd) {
        bottom: auto;
        left: 1%;
        top: 43%;
      }
    }
    .text-1 {
      text-align: right;
      bottom: 15%;
      left: 6%; 

      @include rwd(xmd) {
        left: 2%;
        bottom: 30%;
      }

    }
    .text-2 {
      text-align: left;
      bottom: 15%;
      right: 6%;

      @include rwd(xmd) {
        right: 2%;
        bottom: 30%;
      }
    }
    .text-3 {
      text-align: left;
      top: 40%;
      right: 5%;

      @include rwd(xmd) {
        bottom: auto;
        right: 1%;
        top: 43%;
      }
    }
    .text-4 {
      width: 100%;
      top: 8%;
      margin: 0 auto;
      text-align: center;

      @include rwd(xmd) {
        top: 24%;
      }
    }
  }
  
  .label-circle {
    position: absolute;
    color: $txt-white;
    font-size: 11px;
    background-color: #000;
    text-transform: uppercase;

    @include rwd(xmd) {
      display: none;
    }

    &.label-one {
      top: 38%;
      left: 60%;
    }
    &.label-two {
      top: 44%;
      left: 56%;
    }
    &.label-three {
      top: 50%;
      left: 52%;
    }
  }
}
