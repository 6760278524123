.box-seven-in-one {
  padding: 60px 0;
  width: 100%;
  background: #252525;
  margin-bottom: 60px;

  @include rwd(xmd) {
    padding: 70px 0;
    margin-bottom: 30px;
  }

  .seven-in-one-img-text {
    max-width: 1090px;
    margin: 0 auto;

    @include rwd(xmd) {
      margin: 0 20px;
    }
  }

  .text-img-wrap {
    display: flex;
    
    @include rwd(xmd) {
      flex-wrap: wrap;
    }
  }

  .text {
    width: 50%;
    margin: 8px 5px;

    @include rwd(xmd) {
      width: 100%;
      height: 110vw;
      overflow: hidden;
    }
  }

  .img-wrap-dx {
    display: flex;
    width: 50%;

    @include rwd(xmd) {
      width: 100%;
    }

    .img-subwrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 50%;
      align-items: center;

      @include rwd(xmd) {
        width: 66%;
        flex-wrap: nowrap;
      }

      .image {
        margin: 0 5px;
        width: 100%;

        @include rwd(xmd) {
          margin: 0 0 0 5px;
          width: 48%;
        }
        picture {
          display: block;
        }
        img {
          @include rwd(xmd-up) {
            //height: 100%;
            width:100%;
            max-height: none;
            display: block;
          }
        }
      }
    }
  }

  .text-wrap {
    display: none;
    opacity: 0;
    transition: opacity 2s;
    align-items: center;
    padding: 0 6%;
    height: 100%;

    &.active{
      display: flex;
      opacity: 1;
      flex-wrap: wrap;
    }

    .txt {
      text-align: left;

      @include rwd(xmd) {
        padding: 60px 0;
      }

      h2 {
        margin-bottom: 20px;
      }
    }
  }

  .image {
    opacity: .2;
    max-height: 262px;
    max-width: 265px;
    //width: 50%;
    margin: 0 0 5px 5px;

    &:hover {
      cursor: pointer;
    }

    img {
      max-height: 100%;
    }

    &.img-vertical {
      max-height: 534px;
      overflow: hidden;

      @include rwd(xmd) {
        width: 33%;
        //margin: 0 5px 10px 15px;
        max-height: 100%;
      }

      @media (max-width: $mobile){
        max-height: 125px;
        margin: 0 0 5px 6px;
      }

      @media (min-width: $mobile) and (max-width: $tablet-P){
          max-height: 230px;
          margin: 0 0 5px 7px;
      }

      @media (min-width: $tablet-P) and (max-width: $tablet-L){
            max-height: 251px;
            margin: 0 0 5px 7px;
      }

      img {
        height: 100%;
        width:auto;
        max-height: 100%;
        max-width: none;

        @include rwd(xmd-up) {
          transform: translate(-20% , 0);
        }

        @include rwd(xmd) {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }

    &.active {
      opacity: 1;
    }
  }

  .img-wrap-sub {
    display: flex;
    justify-content: space-between;
    

    .image {
      width: 25%;
      margin: 5px 0;
      margin-left: 5px;

      img {
        width: 100%;
      }

      @include rwd(xmd) {
        width: 33%;
        margin: 0 3px;
      }
    }

    .image.img-oriz {
      max-width: 534px;
      overflow: hidden;
      width: 50%;
      margin-left: 3px;

      @include rwd(xmd) {
        width: 33%;
        margin-left: 1px;
      }

      img {
        max-width: 100%;
        max-height: none;
      }
    }
  }

  .icon-wrap {
    max-width: 80px;
    display: inline-block;

    @include rwd(xmd) {
      width: 20%;
    }
    img {
      max-width: 100%;
    }
    span {
      color: #252525;
      text-transform: uppercase;
      font-size: 12px;
      text-align: center;
      display: block;
       &.icon-title {
        margin-bottom: 40px;
       }
    }
  }
}
