.box-title {
  position: relative;
  max-width: $max-xWidth;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 40px;
  padding: 0 20px;
  color: $white;
  text-align: center;

  @include rwd(md-up) {
    padding: 0;
  }

  .txt-title {
    width: 100%;
    font-size: 30px;
    font-weight: 900;
    @include rwd(md-up) {
      font-size: 40px;
    }
  }
  .caption {
    margin-bottom: 35px;
    @include rwd(md-up) {
      margin-bottom: 60px;
    }
    
  }
  .cta-down {
    margin-bottom: 20px;
  }

  .cta-btn {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  p {
    margin-top: 40px;
    font-size: 1.125rem;
    margin-bottom: 25px; 

    @include rwd(md-up) {
      font-size: 1.2rem;
    }
  }
}
