.img-text-sm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  margin: 60px auto;
  text-align: center;
  position: relative;
  margin-top: 50px;

  @include rwd(md-up) {
    text-align: left; 
    margin: 120px auto; 
  }
 
   &.img-right {
     .image {
      @include rwd(md-up) {
        order: 2;   
      }
     }
   }
  
  .image,
  .text {
    width: 100%;
    

    @include rwd(md-up) {
      width: 50%; 
      flex: 1 0 auto;  
      padding: 0; 
    }
  }

  .image img {
    max-width: 80%;
  }

  .text-wrap {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include rwd(md-up) {
      padding: 0 90px;
    }

  }

  .text {
    height: auto;
    padding: 60px 20px;
    background-color: $bg-black;

    

    @include rwd(sm-up) {
      
      padding: 0;
    }

    @include rwd(md-up) {
      height: auto;
      
    }

    p {
      @include rwd(md) {
      
        padding: 0 20px;
      }
    }

  }

  h3 {
    width: 100%;
    margin-bottom: 40px;
  }
  .caption {
    margin-bottom: 35px;
    @include rwd(md-up) {
      margin-bottom: 60px;
    }
  }

  .cta-btn {
    margin: 0;
    max-width: 160px;
    margin-top: 30px;
  }
  
  .icon-wrap {
    display: flex;
    margin-top: 30px;

    @include rwd(md) {
      justify-content: center;
      margin: 30px 0;
    }

    .icon-feature {
      max-width: 74px;
      text-align: center;
      text-transform: uppercase;
      font-size: 11px;
      margin-right: 20px;

      img {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
}
