$breakpoints: (
    xsmall: 375px,
    small: 600px,
    xmedium: 960px,
    medium: 1024px,
    large: 1280px,
    xlarge: 1366px,
    xxlarge: 1440px,

    max-xsmall: 374px,
    max-small: 599px,
    max-xmedium: 959px,
    max-medium: 1023px,
    max-large: 1279px,
    max-xlarge: 1365px,
    max-xxlarge: 1439px,
);

$xsmall: map-get($breakpoints, xsmall);//375
$small: map-get($breakpoints, small);//600 
$xmedium: map-get($breakpoints, xmedium);//960
$medium: map-get($breakpoints, medium);//1024
$large: map-get($breakpoints, large);//1280
$xlarge: map-get($breakpoints, xlarge);//1366
$xxlarge: map-get($breakpoints, xxlarge);//1440

$max-xsmall: map-get($breakpoints, max-xsmall);//375 -1
$max-small: map-get($breakpoints, max-small);//600 -1
$max-xmedium: map-get($breakpoints, max-xmedium);//960 -1
$max-medium: map-get($breakpoints, max-medium);//1024 -1
$max-large: map-get($breakpoints, max-large);//1280 -1
$max-xlarge: map-get($breakpoints, max-xlarge);//1366 -1 
$max-xxlarge: map-get($breakpoints, max-xxlarge);//1440 -1

@mixin rwd($point) {
    @if $point == xxxl {
        @media only screen and (min-width: $xxlarge) { @content ; }
    }

    @else if $point == xxl {
        @media only screen and (max-width: $max-xxlarge) { @content ; }
    }

    @else if $point == xxl-up {
        @media only screen and (min-width: $xxlarge) { @content ; }
    }

    @else if $point == xl {
        @media only screen and (max-width: $max-xlarge) { @content ; }
    }

    @else if $point == xl-up {
        @media only screen and (min-width: $xlarge) { @content ; }
    }

    @else if $point == lg {
        @media only screen and (max-width: $max-large) { @content ; }
    }

    @else if $point == lg-up {
        @media only screen and (min-width: $large) { @content ; }
    }

    @else if $point == md {
        @media only screen and (max-width: $max-medium) { @content ; }
    }

    @else if $point == md-up {
        @media only screen and (min-width: $medium) { @content ; }
    }

    @else if $point == xmd {
        @media only screen and (max-width: $max-xmedium) { @content ; }
    }

    @else if $point == xmd-up {
        @media only screen and (min-width: $xmedium) { @content ; } 
    }

    @else if $point == sm {
        @media only screen and (max-width: $max-small) { @content ; }  
    }

    @else if $point == sm-up {
        @media only screen and (min-width: $small) { @content ; }  
    }

    @else if $point == xs {
        @media only screen and (max-width: $xsmall) { @content ; }
    }
}

$max-width: 1920px;
$max-xWidth: 1280px;
$max-mWidth: 1024px;

//primary color
$brown: #966e55;
$grey:#3c3c3c;
$white: #ffffff;
$black: #000000;
$red: #B10011;
$antracite: #252525;


//background color
$bg-black:#000000;
$bg-black-blue: #171b2b;
$bg-black-darkblue: #0a0a0e;
$bg-white: $white;
$bg-grey: $antracite;
$bg-brown: $brown;

//palette
$flat-brown:#966e55;
$light-brown:#b48769;

$flat-blue:#8296a0;
$light-blue:#a8b4b5;

$flat-ocra:#b4a050;
$light-ocra:#c8b982;

//text color
$txt-brown: $brown;
$txt-grey: $grey;
$txt-white: $white;
$txt-black: $black;

//base palette
$bg-body: $bg-black;
$bg-shadow: $bg-black-blue;
$bg-square: $bg-grey;
$bg-swipe: $flat-blue;
$bg-square-hover: $flat-blue;

//home palette
$bg-body-home: $bg-black;
$bg-shadow-home: $bg-black-darkblue;
$bg-square-home: $bg-grey;
$bg-swipe-home: $flat-blue;
$bg-square-hover-home: $flat-blue;

//omnichef palette
$bg-body-omni: $bg-black;
$bg-shadow-omni: $bg-black-blue;
$bg-square-omni: $bg-grey;
$bg-swipe-omni: $flat-brown;
$bg-square-hover-omni: $flat-brown;

//speedwave palette
$bg-body-wave: $bg-black;
$bg-shadow-wave: $bg-black;
$bg-square-wave: $flat-brown;
$bg-swipe-wave: $flat-ocra;
$bg-square-hover-wave: $flat-ocra;

@font-face {
	font-family: 'gothic';
	src: url('../fonts/Gothic/gothic.eot');
	src: local('gothic'), url('../fonts/Gothic/gothic.woff') format('woff'), url('../fonts/Gothic/gothic.ttf') format('truetype');
}

//font size px to rem for increase it

@mixin px-to-rem($pxValue, $important: '', $property: "font-size", $standard-size: 20) {
    #{$property}: $pxValue + px + $important;
    #{$property}: $pxValue/$standard-size + em + $important;
}
 
// proprieties for screenReader
@mixin sr-only() {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

@mixin sr-only-focusable {
    &:not(:focus) {
        @include sr-only();
    }
}

@mixin remove-sr-only {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: visible;
    clip: initial;
    border: initial;
}