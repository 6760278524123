
 header {
  position: fixed;
  width: 100%;
  max-width: 100vw;
  top: 0;
  left: 0;
  z-index: 99;
  background-color:rgba(0, 0, 0, 0.7);

  .logo {
    position: relative;
    z-index: 3;
    transition: all 1s;
    @include rwd(md) {
     height: 55px;

     img {
       width: 45px;
       height: inherit;
     }
      
    } 
    @include rwd(md-up) {
      display: none;
    }
    .first-scroll &{
      @include rwd(md-up) {
        display: block;
        margin-top: 8px
      } 
    }
  }

  .logo-big {
    position: relative;
    z-index: 3;
    transition: all 1s;
    display: none;
    @include rwd(md-up) {
      display: flex;
      align-items: center;
    }
    .first-scroll &{
      @include rwd(md-up) {
        display: none;
        
      } 
    }
  }

  .logo-smeg {

      width: 110px;
      position: absolute;
      top: 13px;
      left: calc(50% - 55px);
      z-index: 3;
    

    @include rwd(md-up) {
      width: 144px;
      display: flex;
      align-items: center;
      height: 78px;
      left: auto;
      position: static;
      top: auto;
      
      img {
        width: 100%;
      }
    }
  }
  
   nav {
    position: relative;
    display: flex;
    
    justify-content: space-between;
    border-bottom: 1px solid $white;
    //padding: 20px 0 10px 0;
    margin: 0 35px;
    height: 60px;

    @include rwd(md) {
      align-items: center;
      margin: 0 20px;
      
    }

    @include rwd(md-up) {
      height: 130px;  
      border-bottom: none; 
      transition: all 1s;
      
    }
    .first-scroll &{
      @include rwd(md-up) {
        height: 78px;  
        align-items: center;
        
      } 
    }
  }

}
 #menuToggle{
   
   @include rwd(md) {
    display: block;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
   }
   @include rwd(md-up) {
     width: 100%;
   }
 }
 
 #menuToggle a
 {
   text-decoration: none;
   color: $txt-white;
   transition: color 0.3s ease;
 }
 
 #menuToggle a:hover
 {
   font-weight: 900;
 }
 
 
 #menuToggle input
 {
   display: block;
   width: 40px;
   height: 32px;
   position: absolute;
   top: 25px;
   right: 0;
   cursor: pointer;
   opacity: 0; /* hide this */
   z-index: 4; /* and place it over the hamburger */
   -webkit-touch-callout: none; 

   @include rwd(md-up) {
     display: none;

   }
 }
 
 /*
  * Just a quick hamburger
  */
 #menuToggle span
 {
   display: block;
   width: 22px;
   height: 1px;
   margin: 4px 0;
   position: relative;
   background: $white;
   border-radius: 3px;
   z-index: 3;
   transform-origin: 4px 0px;
   transition: 0.4s;
   @include rwd(md-up) {
    display: none;

  }
 }
 
 #menuToggle span:first-child
 {
   transform-origin: 0% 0%;
 }
 
 #menuToggle span:nth-last-child(2)
 {
   transform-origin: 0% 100%;
 }
 
 /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
 #menuToggle input:checked ~ span
 {
   opacity: 1;
   transform: rotate(45deg) translate(-2px, 0);
   background: $white;
 }
 
 /*
  * But let's hide the middle one.
  */
 #menuToggle input:checked ~ span:nth-last-child(3)
 {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);
 }
 
 /*
  * Ohyeah and the last one should go the other direction
  */
 #menuToggle input:checked ~ span:nth-last-child(2)
 {
   transform: rotate(-45deg) translate(-2px, 0);
 }
 
 /*
  * Make this absolute positioned
  * at the top left of the screen
  */
 #menu
 {
   

   @include rwd(md) {
    position: absolute;
    width: calc(100% + 60px);
    top: 0;
    left: -30px;
    // margin: -110px 0 0 -50px;
    padding-top: 100px;
    background: $bg-black;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */
    transform-origin: 0% 0%;
    transform: translate(0, -100%);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }

  @include rwd(md-up) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 78px;
  }
 }
 
 #menu li
 {
   padding: 25px 20px;
   font-size: 22px;
   text-align: center;
   color: $txt-white;
   text-transform: uppercase;

   @include rwd(md-up) {
      padding: 0 1.5%;
   }

   @include rwd(lg-up) {
    padding: 0 2%;
 }

   a {
     font-size: 22px;
     opacity: 0.6;
     @include rwd(md-up){
      font-size: 16px;
     }
   }
 }
 
 /*
  * And let's slide it in from the left
  */
 #menuToggle input:checked ~ ul
 {
   transform: none;
   z-index: 1;

   &::after{
    content : "";
    width: calc(100% - 60px);
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    height: 1px;
    border-bottom: 1px solid $white;
   }
   
 }

 body {
   &.home {
     .menu-0 {
       font-weight: 900;

       a {
         opacity: 1 !important;
       }
     }
   }
   &.omnichef {
    .menu-1 {
      font-weight: 900;
      a {
        opacity: 1 !important;
      }
    }
  }
  &.speedwave {
    .menu-2 {
      font-weight: 900;
      a {
        opacity: 1 !important;
      }
    }
  }
  &.steam {
    .menu-3 {
      font-weight: 900;
      a {
        opacity: 1 !important;
      }
    }
  }
 }