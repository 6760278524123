// Extra large devices (large desktops, 1367px and up)
$desktop-XL: 1367px;

// Extra large devices (large desktops, 1200px and up)
$desktop: 1200px;

// Large devices (desktops, 992px and up)
$tablet-L: 992px;

// Medium devices (tablets, 768px and up)
$tablet-P: 768px;

// Small devices (landscape phones, 576px and up)
$mobile: 576px;


$max-screen-size:1920px;
#cookie-banner {
  display: none;
  position: fixed;
  z-index: 300;
  background: white;
  bottom: 0; 
  width: 100%;
  color: black;
  padding: 5px 15px;
  text-align: left;
  z-index: 100;
  font-weight: 100;
  left: 0;

  .col-sm-10 {
    padding: 1rem;
  }

  .col-sm-2 {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      max-height: 3rem;
      font-family: Raleway,sans-serif;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1.92px;
      line-height: 24px;
      text-transform: uppercase;
      background-color: #020202;
      color: #a0a0a0;
      border: 1px solid #a0a0a0;
      text-align: center;
    }
  }

  .cookie__info {
    color: black;
    text-decoration: underline;
    margin-left: 15px;
    margin-right: 15px;
  }

  .btn, .modal-gallery-btn {
    min-width: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
  }
}

#cookie-banner > .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

#cookiefirst-cookies-table table,
#cookiefirst-policy-page table {
  //color: $t-color-primary;
}

[data-cookiefirst-widget="modal"] div div [dir="ltr"] button{
  flex:1 1!important;
  margin-right:4px;
  margin-left:unset;
  margin-bottom:0px;
  border:1px solid rgba(150, 136, 112, 1) !important;
}

button{
  &[data-cookiefirst-outline-accent-color]:not([class]) {
    all:inherit;
    //Action-link
    display: inline-block;
    position: relative;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.44px;
    line-height: 20px;
    width: 100%;
    &:before{
      content: "";
      display: inline-block;
      margin-right: 20px;
      line-height: 20px;
      border-top: 1px solid;
      width: 40px;
      vertical-align: middle;
      transition: all 1s ease-in-out;
    }
    &:hover {
      text-decoration: none;
      &:before {
        margin-right: 30px;
        width: 50px;
        transition: all 1s ease-in-out;
      }
    }
  }
}

.cookiefirst-cookie-declaration{
  /* typography reset */

  h1, h2, h3,
  h4, h5, h6 {
    &:not([class]) {
      font-weight: bold;
      letter-spacing: 1.3px;
    }
  }
 
  h1, h2, h3,
  h4, h5, h6 {
    &:not([class]) {
      padding-bottom: .5em;
      @media (min-width: $tablet-L) {
        padding-bottom: .8em;
      }
      @media (min-width: $desktop) {
        padding-bottom: 1em;
      }
    }
  }
  h1:not([class]) {
    font-size: 1.8em;
    @media (min-width: $tablet-L) {
      font-size: 1.9em;
    }
    @media (min-width: $desktop) {
      font-size: 2em;
    }
  }

  h2:not([class]) {
    font-size: 1.3em;
    @media (min-width: $tablet-L) {
      font-size: 1.4em;
    }
    @media (min-width: $desktop) {
      font-size: 1.5em;
    }
  }

  h3:not([class]) {
    font-size: 1.13em;
    @media (min-width: $tablet-L) {
      font-size: 1.15em;
    }
    @media (min-width: $desktop) {
      font-size: 1.17em;
    }
  }

  h4:not([class]) {
    font-size: .9em;
    @media (min-width: $tablet-L) {
      font-size: .95em;
    }
    @media (min-width: $desktop) {
      font-size: 1.00em;
    }
  }

  h5:not([class]) {
    font-size: .75em;
    @media (min-width: $tablet-L) {
      font-size: .8em;
    }
    @media (min-width: $desktop) {
      font-size: .83em;
    }
  }

  h6:not([class]) {
    font-size: .6em;
    @media (min-width: $tablet-L) {
      font-size: .65em;
    }
    @media (min-width: $desktop) {
      font-size: .67em;
    }
  }
  /* fine typography reset */ 
}