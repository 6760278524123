.box-test {
  text-align:center;
  padding: 60px 10px;
  background-color: #252525;
  color: $txt-white;

  .cta-btn {
    margin-top: 30px;
  }

  h2 {
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  .caption {
    margin-bottom: 60px;
  }

  .modal.modal-simply {
    background-color: initial;
    padding: 0;

    .modal-dialog {
      padding: 0;
      height: 97vh;
      
      .modal-wrap {
        border: none;
        padding: 0;
        height: auto;

        .modal-body {
          padding: 0; 

          .question-image {
            max-height: 35vh;
            overflow: hidden;

            @include rwd(md) {
              max-height: 25vh;
            }

            img {
              transform: translate(0 , -30%);
            }
          }
        }
      }
    }

    .modal-wrap {
      border: none;
      padding: 0;

      .close{
        @include rwd(md-up) {
          background: 0;
          font-size: 30px;
          right: -40px;
          top: -8px;
          font-weight: normal;
        }
      }
    }
  }

  .modal .modal-content {
    color: $txt-grey;
    width: auto;
    text-align: left;
    padding: 0;
    max-width: 570px;
    height: auto;
    margin: 0 auto;

    @include rwd(md) {
      width: calc(100vw - 20px);
    }
    p,
    h2 {
      color: $txt-grey;
    }

    .cta-btn.cta-brown {
      color: $txt-brown;
      margin: 30px auto;
      display: block;

      &:hover{
        color: $txt-white;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    .question-wrap {
      padding: 30px;

      @include rwd(md) {
        padding: 30px;

      }
    }

    .question{
      font-size: 22px;
      margin-bottom: 20px;
      font-weight: 900;
    }

    .answer {
      font-size: 18px;
      @include rwd(md-up) {
        display: grid;
        grid-template-rows: repeat(6, min-content);
        grid-auto-flow: column;
        column-gap: 10px;
      }

      .form-check{
        padding: 7px 0;
        display: flex;
        align-items: center;

        .form-check-input{
          margin-right: 15px;
          width: 20px;
          height: 20px; 
         
        }
      }
      .form-check-label {
        width: 95%;
      }
    }

    .count-step {
      text-align: center;
    }
    .final-wrap {
      text-align: center;
      padding: 40px;
  
      .intro {
        font-size: 20px;
        margin-bottom: 50px;
      }
      .title {
        margin-bottom: 15px;
        text-transform: uppercase;
        font-size: 1.602rem;
        font-weight: 900;

        @include rwd(md-up) {
          font-size: 2.074rem; 
        }
      }
      
      .caption {
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 40px;
      }
  
      p {
        padding: 0 20px;
      }
  
      a.cta-btn.cta-brown {
        margin-bottom: 0;
        max-width: 230px;
        color: $txt-white;
        margin-top: 40px;
  
        &:hover {
          color: $txt-brown;
        }

        
      }
    }
  }
  .modal-simply .modal-dialog .modal-content .modal-wrap .close {
    @include rwd(md) {
      top: -5px;
      right: -5px;
      z-index: 9;
    }
  }

  .back-btn {
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: -24px;

    &:before {
      content: "<";
      font-size: 24px;
      margin-right: 5px;
    }
  }

  #popup-step-1 {
    padding: 100px 0 !important;
  }
  
}
