.box-text {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  text-align: center;
  flex-direction: column;
  max-width: $max-xWidth;
  padding: 40px 20px;
  color: $white;
  text-align: center;

  @include rwd(md-up) {
    padding: 60px 60px 30px 60px;

  }

  h3 {
    width: 100%;
    font-size: 20px;

    @include rwd(md-up) {
      font-size: 40px;
  
    }
  }
  .caption {
    margin-bottom: 60px;
  }
  .cta-down {
    margin-bottom: 20px;
    margin-top: 30px;
    color: $white;
  }

  p {
    font-size: 1.125rem;
    margin-bottom: 25px; 
    margin-top: 40px;

    @include rwd(md-up) {
      font-size: 1.2rem;
    }
  }
}
