.box-slider-img-centered {
  max-width: 1560px;
  margin: 0 auto;
  padding: 30px 20px;

  @include rwd(md-up) {
    padding: 60px 70px;
  }

  @include rwd(lg-up) {
    padding: 50px 100px; 
  }

  @include rwd(xxl-up) {
    padding: 50px 200px; 
  }

  .slider-img-centered {
    opacity: 0;
    transition: opacity 0.5s;

    &.slick-initialized {
      opacity: 1;
    }
  }
  .slide-wrap {
    overflow: hidden;
    position: relative; 
    padding: 5px;
    
    @include rwd(md-up) {
      display: flex !important;
      justify-content: center;   
      padding: 15px;
    }
    &:hover {
      .text-overlay {
        top:15px;

        @include rwd(md) {
          top: 0;
        }
      }
    }
  }

  .text-overlay {
    top: 100%;
    position: absolute;
    overflow: hidden;
    text-align: center;
    background-color: $bg-square-hover;
    height: calc(100% - 50px);
    width: calc(100% - 50px);
    padding: 10px;
    -moz-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
    -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
    transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;

    @include rwd(md) {
      height:100%;
      width: calc(100% - 10px);
      padding: 0;
    }

      .home & {
        background-color: $bg-square-hover-home;
      }

      .speedwave &{
        background-color: $bg-square-hover-wave;
      }
    
      .omnichef &{
        background-color: $bg-square-hover-omni;
      }

      p,
      h3,
      caption {
        @include rwd(md) {
          padding: 0 10px;
        }
      }
    }

  .slick-arrow.slider-arrow {
    border: 1px solid $brown;
    opacity: 0.6;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    font-family: 'nomospace';
    font-size: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &.slick-next{
      
      @include rwd(md-up) {
        right: -60px;
      }
    
      @include rwd(xl-up) {
        right: -75px;
      }

      &:before {
        content: ">";
        font-family: monospace;
      }
    }

    &.slick-prev {
      @include rwd(md-up) {
        left: -60px;
      }
    
      @include rwd(xl-up) {
        left: -75px;
      }
      &:before {
        content: "<";
        font-family: monospace;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .slick-dots {
    bottom: auto;
     
    li {
      border: 1px solid $white;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: 0 10px;

      @include rwd(md) {
        height: 5px;
        width: 5px;
      }

      &.slick-active {
        background-color: $white;
      }
    }
    
  }
 
  
  .image,
  .text {
    //width: 100%;

    @include rwd(md-up) {
      //width: 50%; 
      flex: 1 0 auto;   
    }
  }

  .image {
    width: 100%;

    img {
      width: 100%;
    }
  } 

  .text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: calc(100% - 40px);
    align-items: center;
    padding: 20px 0;

    .icon-list-wrap {
      display: flex;
      justify-content: space-around;
      .icon-wrap {
        width: 20%;
        margin: 0 10px;

        img {
          max-width: 80px;
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }

  .text {
    height: auto;
    background-color: $bg-square;

    @include rwd(md-up) {
      height: auto;
    }

    &.bg-black {
      background-color: $bg-black;
    }
    &.bg-black-blue {
      background-color: $bg-black-blue;
    }
    &.bg-black-darkblue {
      background-color: $bg-black-darkblue;
    }
  }

  h3 {
    
    font-size: 30px;

    @include rwd(md-up) {
      width: 100%;
    }

    @include rwd(xl-up) {
      font-size: 50px;
    }
  }
  .caption {
    margin-bottom: 10px;
    font-size: 16px;
    @include rwd(xl-up) {
      margin-bottom: 20px;
      font-size: 22px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5em;

    @include rwd(xl-up) {
      font-size: 20px;
    }
  }

  .cta-btn {
    margin: 0;
    max-width: 160px;
  }
  
}
