.slider-three-item,
.item-container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 30px 0;
  opacity: 0;

  &.slick-initialized {
    opacity: 1;
  }

  .slick-list {
    display: flex;
    //justify-content: center;
  }

  .slick-track {
    min-width: 1200px;
    justify-content: center;
  }

  @include rwd(md-up) {
    padding: 0px 100px; 
  }

  .slider-item {
    min-width: 200px;

    img {
      width: 100%;
    }
  }

  .slick-arrow.slider-arrow {
    border: 1px solid $brown;
    opacity: 0.6;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-family: 'nomospace';
    font-size: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &.slick-next{
      right: -45px;
      &:before {
        content: ">";
        font-family: monospace;
      }
    }

    &.slick-prev {
      left: -45px;
      &:before {
        content: "<";
        font-family: monospace;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .slick-dots {
    bottom: -75px;

    li {
      border: 1px solid $white;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: 0 10px;

      @include rwd(md) {
        height: 5px;
        width: 5px;
      }

      &.slick-active {
        background-color: $white;
      }
    }
    
  }
 
  .image,
  .text {

    @include rwd(md-up) {
      flex: 1 0 auto;   
    }
  }

  .image img {
    width: 100%;
  }

  .text-wrap {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

  }

  .text {
    height: auto;
    padding: 60px 0;

    @include rwd(sm-up) {
      padding: 0;
    }

    @include rwd(md-up) {
      height: auto;
    }
  }

  h3 {
    width: 100%;
    margin-bottom: 40px;
  }
  .caption {
    margin-bottom: 35px;
    @include rwd(md-up) {
      margin-bottom: 60px;
    }
  }

  .cta-btn {
    margin: 0;
    max-width: 160px;
  }
  
}

#confStepOne {
  .bg-progress {
    width: 33%;
  }
}
#confStepTwo {
  .bg-progress {
    width: 66%;
  }
}
#confStepThree {
  .bg-progress {
    width: 100%;
  }
}

#confStepOne,
#confStepTwo,
#confStepThree {
  .slider-item {
    background: linear-gradient(#0f0f0f, #1f1e1e, #0f0f0f);
    text-align: center;
    margin: 3px;
    padding: 30px 20px;


    .cta-btn {
      margin: 30px 0;
    }

    &:hover {
      background: $bg-grey;
    }

    .category {
      font-size: 18px;
      margin: 20px 0;
    }

    .name {
      font-size: 22px;
      font-weight: bold;
    }
  }

}
