.box-two-text {
  position: relative;
  text-align: center;
  max-width: 1920px;
  padding: 10px 20px;
  margin: 0 auto;

  @include rwd(md-up) {
    padding: 60px 30px;
  }

  @include rwd(lg-up) {
    padding: 60px 100px;
  }

  .two-box-wrap {
    flex-wrap: wrap;
    align-items: center;
    display: flex;
    justify-content: space-around;
    

    @include rwd(md-up) {
      flex-wrap: nowrap;
    }

    .box-one-text {
      text-align: center;
      flex-direction: column;
      position: relative;
      display: flex;
      justify-content: center;
      height: 98vw;
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 70%;
      
      @include rwd(md-up) {
        width: 48%;
        max-width: 805px;
        height: calc(45vw - 100px); 
        max-height: 680px; 
        background-size: 75%;
        background-color: $bg-grey;
      } 

      @include rwd(md) { 
        background-image: none !important;
        height: auto;
        margin-bottom: 35px;
        padding-bottom: 35px;
      }

      &:first-of-type {
        @include rwd(md) { 
        border-bottom: 1px solid #BAC3C4;
        }
      }

      &:hover {
        background-color: transparent;
      }

      p {
        padding: 0 30px;
      }
    }
  }

  h3 {
    width: 100%;
    margin-bottom: 40px;
  }
  .caption {
    margin-bottom: 40px;
    @include rwd(md-up) { 
      margin-top: -5%;
    }

  }
  .cta-btn {
    margin-top: 30px;
  }
}
