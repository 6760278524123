.box-img-text {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 1s;
  position: relative;
  color: $white;
 
   &.img-right {
     .image {
      @include rwd(md-up) {
        order: 2;   
      }
     }
   }
  
  .image,
  .text {
    width: 100%;

    @include rwd(md-up) {
      width: 50%; 
      flex: 1 0 auto;   
    }
  }

  .image img {
    width: 100%;
    display: block;
  }

  .image {
    picture {
      display: block;

      img {
        display: block;
      }
    }
  }

  .text-wrap {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: left;
    align-items: flex-start;
   

    @include rwd(md-up) {
      padding: 30px 60px;
      height: calc(100% - 60px);
    }

    @include rwd(lg-up) {
      padding: 60px 90px;
      height: calc(100% - 120px);
    }

    
  }
  &.img-right {
    .text-wrap {
      text-align: left;
      align-items: flex-start;
    }
  }

  .text {
    height: auto;
    background: $bg-grey;
    padding-bottom: 30px;

    @include rwd(sm) {
      max-height: 600px;
    }

    @include rwd(md-up) {
      padding-bottom: 0;
    }

  }

  .image-dots {
    height: 22px;
    margin-bottom: 35px;
    @include rwd(md-up) {
      margin-bottom: 25px;
    }
    @include rwd(xl-up) {
      margin-bottom: 50px;
    }

    img {
      max-height: 100%;
      width: auto;
    }
  }

  h3 {
    width: 100%;
    margin-bottom: 35px;
    @include rwd(md-up) {
     
      margin-bottom: 20px;
    }
    @include rwd(xl-up) {
      margin-bottom: 50px;
      
    }
  }
  .caption {
    margin-bottom: 35px;
    
    @include rwd(md-up) {
      margin-bottom: 20px;
    }
    @include rwd(xl-up) {
      
      margin-bottom: 50px;
    }
  }


  .cta-btn {
    max-width: 160px;
    margin: 0;
    margin-top: 30px;
    
    @include rwd(xl-up) {
      margin: 0;
      margin-top: 50px;
    }
  }
  
}
