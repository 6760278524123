.box-video {
  position: relative;
  min-height: 100vh;
  width: 100%;
  height: auto;

  &#component-1 {
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        @media (orientation: landscape) { 
          padding-top: 44px;
        }
      }
    }
  } 
  
  &#component-1,
  .plyr__video-wrapper {
    min-height: 100vh;

    @include rwd(md) {
      max-height: 1300px;
      min-height: 610px;
     }

     @include rwd(sm-up) {
      @media (orientation: portrait) { 
        max-height: 66vh;
      }
    }

    @include rwd(sm) {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      @media (orientation: portrait) { 
       
      }
    }
  }

  

  // .rotate-icon {
  //   @include rwd(md) {
  //     @media (orientation: portrait) { 
  //       display: block;
  //       width: 60px;
  //       height: 60px;
  //       background-image: url("../images/galileo/screen-rotation-4.svg");
  //       background-repeat: no-repeat;
  //       background-position: center;
  //       background-size: contain;
  //       background-color: #fff;
  //       border-radius: 5px;
  //       border: 10px solid #fff;
  //       position: absolute;
  //       left: 30px;
  //       bottom: 65px;
  //       z-index: 9;
  //     }
  //   }
  // }

  &.no-video#component-1 {
    //height: 100vh; 
    overflow: hidden;  

    @include rwd(sm) {
      min-height: 100vh;
    }

  }
  iframe,
    video {
      width: 100%;
    }
    iframe,
    video,
    .js-player,
    .plyr {
      display: none;
    }

  &#component-1  {
    iframe,
    video,
    .js-player,
    .plyr {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      display: block;

      @include rwd(sm-up) {
        height: auto;
        width: 100%;
        display: none;
      }
    }
    &.isPlaying {
      iframe,
      video,
      .js-player,
      .plyr{ 
        display: block;
      }
      
    }
  }


  .plyr--stopped {
    @include rwd(sm-up) {
      opacity: 0;
    }
  }

  .plyr--playing {
    opacity: 1;
  }

  &#component-1 .plyr--paused {
    
    @include rwd(sm-up) {
      height: 100vh;
      overflow: hidden;
    }
  }

  &#component-1 .plyr__video-embed {
    @include rwd(md-up) {
      aspect-ratio: 16/9 !important;
    }

  }

  &#component-2,
  &#component-3,
  &#component-4,
  &#component-5,
  &#component-6,
  &#component-7,
  &#component-8,
  &#component-9,
  &#component-10 {
    height: auto;
    min-height: auto;

    iframe,
    video,
    .js-player,
    .plyr {
      padding-bottom: 40px;
      position: relative;
      display: block;
      
    }

  }

  .video-overlay {
    position: relative;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 4;
    height: auto;
    background-position: top center !important;
    

    @include rwd(sm) {
      min-height: 100vh;
      background-size: contain !important;
      position: relative;
      order: -1;
    }

    .overlay-text-wrap {
      padding: 0 20px;
      position: relative;
      padding-top: 37.5%;
      line-height: 1.1;

      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        @media (orientation: landscape) { 
          padding-top: calc(37.5% + 44px);
        }
      }

      @include rwd(sm) {
        padding-top: 95.5%;
       }
    }
   
    h2 {
      width: 100%;
      
      margin: 10px 0;
 
      @include rwd(md) {
        
        line-height: 35px;
      }
    }

    .video-pretitle {
      width: 100%;
      
      @include rwd(md) {
       
      }
    }

    .video-subtitle {
      width: 100%;
      
      text-transform: uppercase;

      @include rwd(md) {
        
      }
    }

    .cta-btn {
      margin: 30px 0 40px 0;
    }
    .cta-down {
      width: 100%;
      margin-top: 35px;
      display: none;
    }
  }

  &.no-video {
    .video-overlay {
      @include rwd(sm) {
        height: 100%;
        background-size: contain !important;
        order: 2;
      }
    }
   
  }

  &.isPlaying {
    .video-overlay {
      display: none;

      @include rwd(sm) {
        display: block;
      }
    }
  }

  &#component-1 {
    .video-overlay {
     opacity: 1;
    }
  }

  &#component-2,
  &#component-3,
  &#component-4,
  &#component-5,
  &#component-6,
  &#component-7,
  &#component-8,
  &#component-9,
  &#component-10 {
    .video-overlay {
     display: none;
    }
  }

  .plyr {
    > div {
      width: 100%;
    }
  }
}