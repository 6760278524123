.box-product {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  position: fixed;
  bottom: 0;
  overflow: hidden;
  display: flex;
  text-align: left;

  @include rwd(sm-up) {
    width: 100%;
  }
  

  &.open {
    max-height: 80vh;
    height: auto;
    padding: 0;
    padding: 30px;
  }

  .image{
    img {
      width: 100%;
    }
  }
  .product-wrap {
    display: flex;
    max-width: 1240px;
    margin: 0 auto;
    height: fit-content !important;
  }
  .text-actions-wrap {

    @include rwd(sm-up) {
      display: flex;
    }

    .text {
      margin-bottom: 30px;
      @include rwd(sm-up) {
        margin-bottom: 0;
      }
    }

    .actions {
      button {
        margin-bottom: 20px;
      }
    }
  }
}