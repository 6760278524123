img { border: 0; }


/* Section Services */

  .services.box-rotatescroll {
    width: 100%;
    //height: 120vh;
    text-align: center;
    color: $white;
    padding-bottom: 240px;
    background-color: #252525;
    position: relative;
    z-index: 0;

    @include rwd(md) {
      padding-bottom: 290px; 
    }

    h2 {
      padding-top: 40px;
      padding-bottom: 25px;
      text-transform: uppercase;

      @include rwd(md) {
        padding: 40px 20px;
      }
    }
    
    .services {
      display: flex;
      align-items:flex-start;
      justify-content: space-between;

      > * {
        flex-grow: 1;
      }

     
    }

    .circle--rotate {
        border-radius: 50%;
        border: 2px $brown solid;
        width: 60%;
        height: 60%;
        margin: 0 auto 0;
        position: relative;
        transform: rotate(0deg);
        z-index: 9;
        padding: 0;

        @include rwd(md-up) {
          width: 27%;
          height: 27%;
        }
      
        > li {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 50%;
          z-index: 10;
          visibility: hidden;
      
          .icon {
            visibility: visible;
            cursor: pointer;
            text-align: center;
            overflow: hidden;
            font-size: 20px;
            color: #ffffff;
            border-radius: 50%;
            //background-color: #000000;
            height: 10%;
            width: 10%;
            margin: -2% auto;
            transition: all 500ms ease;
            //box-shadow: -10px 10px 25px rgba(0,0,0,.2);
      
            &:hover {
            }
          }
      
          i {
            color: #ffffff;
            font-size: 24px;
            margin: auto;
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: table;
      
            &::before {
              display: table-cell;
              vertical-align: middle;
            }
          }
      
          img {
            margin: auto;

            &.arrow {
              height: 16px;
              position: absolute;
              top: 40px;
              left: 15px;

              @include rwd(sm) {
                top: 25px;
                height: 10px;
                left: -8px;
                transform: rotate(-15deg);
              }
            }
          }
        }
      }
      
      .circle--slider {
        position: relative;
      
        .rotate--circle {
          margin: 0 auto;
        }
      
        .circle--rotate > li {
      
          div {
            position: relative;
            overflow: visible;
            //background-color: #1F2845;
      
            span {
              transform: rotate(0deg);
              transition: all 2s;
              opacity: 0;
            }
          }
      
          &.active div {
            font-size: 20px;
            //background-color: #654BE4;
            transition: all 1s;
      
            span {
              width: 100%;
              height: 100%;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              transform: rotate(-45deg);
              z-index: -1;
              opacity: 1;
      
              &:before {
                position: absolute;
                height: 1px;
                top: -50%;
                right: 140%;
                bottom: 0;
                margin: auto;
                content: "";
                width: 30%;
                height: 30%;
                filter: blur(3px);
                opacity: .7;
                //border: 6px solid #F48E2A;
                z-index: -1;
              }
      
              &:after {
                position: absolute;
                height: 1px;
                top: 100%;
                left: 130%;
                bottom: 0;
                margin: auto;
                content: "";
                width: 40%;
                height: 40%;
                border-radius: 50%;
                opacity: .7;
                //border: 8px solid #FE9290;
                z-index: 5;
              }
            }
          }
        }
      
        .count2 li:nth-child(2) {
          transform: rotate(180deg);
        }
      
        .count3 li {
          &:nth-child(2) {
            transform: rotate(120deg);
          }
          &:nth-child(3) {
            transform: rotate(240deg);
          }
        }
      
        .count4 li {
          &:nth-child(2) {
            transform: rotate(90deg);
          }
          &:nth-child(3) {
            transform: rotate(180deg);
          }
          &:nth-child(4) {
            transform: rotate(270deg);
          }
        }
      
        .count5 li {
          &:nth-child(2) {
            transform: rotate(72deg);
          }
          &:nth-child(3) {
            transform: rotate(144deg);
          }
          &:nth-child(4) {
            transform: rotate(216deg);
          }
          &:nth-child(5) {
            transform: rotate(-72deg);
          }
        }
      
        .count6 li {
          &:nth-child(2) {
            transform: rotate(60deg);
          }
          &:nth-child(3) {
            transform: rotate(120deg);
          }
          &:nth-child(4) {
            transform: rotate(180deg);
          }
          &:nth-child(5) {
            transform: rotate(240deg);
          }
          &:nth-child(6) {
            transform: rotate(-60deg);
          }
        }
      
        .count7 li {
          &:nth-child(2) {
            transform: rotate(51.5deg);
          }
          &:nth-child(3) {
            transform: rotate(103deg);
          }
          &:nth-child(4) {
            transform: rotate(154.5deg);
          }
          &:nth-child(5) {
            transform: rotate(206deg);
          }
          &:nth-child(6) {
            transform: rotate(-103deg);
          }
          &:nth-child(7) {
            transform: rotate(-51.5deg);
          }
        }
      
        .count8 li {
          &:nth-child(2) {
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            transform: rotate(90deg);
          }
          &:nth-child(4) {
            transform: rotate(135deg);
          }
          &:nth-child(5) {
            transform: rotate(180deg);
          }
          &:nth-child(6) {
            transform: rotate(-135deg);
          }
          &:nth-child(7) {
            transform: rotate(-90deg);
          }
          &:nth-child(8) {
            transform: rotate(-45deg);
          }
        }
      
        .count9 li {
          &:nth-child(2) {
            transform: rotate(40deg);
          }
          &:nth-child(3) {
            transform: rotate(80deg);
          }
          &:nth-child(4) {
            transform: rotate(120deg);
          }
          &:nth-child(5) {
            transform: rotate(160deg);
          }
          &:nth-child(6) {
            transform: rotate(-160deg);
          }
          &:nth-child(7) {
            transform: rotate(-120deg);
          }
          &:nth-child(8) {
            transform: rotate(-80deg);
          }
          &:nth-child(9) {
            transform: rotate(-40deg);
          }
        }
      
        .count10 li {
          &:nth-child(2) {
            transform: rotate(36deg);
          }
          &:nth-child(3) {
            transform: rotate(72deg);
          }
          &:nth-child(4) {
            transform: rotate(108deg);
          }
          &:nth-child(5) {
            transform: rotate(144deg);
          }
          &:nth-child(6) {
            transform: rotate(-180deg);
          }
          &:nth-child(7) {
            transform: rotate(-144deg);
          }
          &:nth-child(8) {
            transform: rotate(-108deg);
          }
          &:nth-child(9) {
            transform: rotate(-72deg);
          }
          &:nth-child(10) {
            transform: rotate(-36deg);
          }
        }
      
        .animate-wrapper {
          width: 50%;
          height: 50%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          border-radius: 50%;

          @include rwd(md-up) {
            width: 21%;
            height: 21%;

          }
         
        }
      
        .animate-title {          
          color: #fff;

          @include rwd(md) {
            padding: 0 20px;

          }
        }
        
      
        .animate-img {
          width: 30%;
          height: 30%;
          border-radius: 50%;
          overflow: hidden;
      
          .animate-img__in {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 50%;
            background-repeat: no-repeat;
            z-index: 3;
            transform: rotate3d(0, 0, 0, 0deg) scale(.85);
            transition: .55s ease-in-out;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-size: cover;
            box-shadow: -20px 20px 50px rgba(0,0,0,.3);
          }
        }
      
        .animate-more {
          opacity: 0;
          visibility: hidden;
          transition: all .5s .4s;
          min-width: 131px;
          width: 200%;
          //overflow: hidden;
          text-align: center;
          //z-index: 30;
          position: absolute;
          //top: 0;
          left: 0;
          right: 0;
          bottom: -60%;
          top: 55%;
          margin: auto;
          padding: 15px;
          //height: 62px;
          transform: scale(.8) translate(-50%, 50%);
          transition: opacity .55s ease-in-out;
          //background-color: #252525;
          padding-bottom: 20px;

          @include rwd(md-up) {
            bottom: -80%;
            top: 70%;
            width: 400%;

          }
        }
      
        .animate.active {
      
          .animate-more {
            opacity: 1;
            visibility: visible;
            transform: scale(1) translate(-27%, 60%);

            @include rwd(md-up) {
              transform: scale(1) translate(-37%, 50%);
  
            }
          }
          .animate-img  .animate-img__in {
            opacity: 1;
            transform: scale(1);
          }
        }
      
        .prev,
        .next {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          display: block;
          height: 62px;
          width: 62px;
          text-align: center;
          cursor: pointer;
      
           span {
            writing-mode: vertical-rl;
            text-orientation: upright;
            font-weight: 700;
          }   
        }
      
        .prev {
          left: 250px;
          display: none;
        }
      
        .next {
          visibility: visible;
          opacity: 1;
          top: -17px;
        }
      }

      .cyrcle-text {
        text-align: center;
        padding: 0 10px;
        font-size: 1.125rem;
        
          @include rwd(md-up) {
            font-size: 1.2rem;
          }
        
        .txt-title {
            font-weight: bold;
            margin-bottom: 20px;
            font-size: 1.802rem; 
            font-weight: 900;

            @include rwd(md-up) {
              font-size: 2.488rem; 
            }
        }
    }

    .cyrcle-dots {
      position: absolute;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      z-index: 4;

      li {
        border: 1px solid $white;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        margin: 0 10px;
        color: transparent;
  
        @include rwd(md) {
          height: 5px;
          width: 5px;
        }

        button {
          color: transparent;
        }
  
        &.dot-active {
          background-color: $white;
        }
      }
    }
  }
  
  