html,
body {
  font-family:  'gothic', 'Century Gothic', CenturyGothic, AppleGothic, sans-serif !important;
  font-size: 16px;
  background-color: $bg-body;
  overflow-x: hidden;
  color: $txt-white;
  line-height: 1.4;

  @include rwd(md-up) {
    font-size: 12px;
  }

  @include rwd(md-up) {
    font-size: 16px;
  }
  
  &.home{
    background-color: $bg-body-home;
  }

  &.speedwave{
    background-color: $bg-body-wave;
  }

  &.omnichef{
    background-color: $bg-body-omni;
  }
 
  &.bodyBlock {
  
    @include rwd(sm-up) {
      overflow: hidden;
      
    }
  }
 
}

*:focus {
  outline: none;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 35px;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  font-size: 2.027rem; 

  @include rwd(md-up) {
    font-size: 2.986rem; 
  }
}

h2 {
  font-size: 1.802rem; 
  font-weight: 900;

  @include rwd(md-up) {
    font-size: 2.488rem; 
  }

}

h3 {
  font-size: 1.602rem;
  font-weight: 900;

  @include rwd(md-up) {
    font-size: 2.074rem; 
  }
}

.video-subtitle,
.video-pretitle,
h4 {
  font-size: 1.266rem;

  @include rwd(md-up) {
    font-size: 1.44rem; 
  }
}

p {
  font-size: 1rem;
  line-height: 26px;
}

a,
.cta-btn {
  font-size: 0.889rem;
  text-decoration: none;
  @include rwd(md-up) {
    font-size: 0.833rem; 
  }
}

button{
  margin: 0;
  background: none;
  border: none;
  padding: 0;
}

.caption {
  font-size: 1.125rem;

  @include rwd(md-up) {
    font-size: 1.2rem; 
  }
}

.txt-white {
  color: $txt-white;
}

.txt-upper {
  text-transform: uppercase;
}

u {
  text-decoration: none;
  color: $txt-brown;
}

.txt-brown {
  color: $txt-brown;
}

.txt-grey {
  color: $txt-grey;
}

.txt-black {
  color: $txt-black;
}


html{
  height: 100%;
}

img{
  max-width: 100%;
  height:auto;
}

.wrapper.container {
  max-width: 1920px !important;
  margin: 0 auto !important;
  overflow: hidden;
  min-width: 160px;
}

.btn-cta,
.btn-cta-btn {
  display: inline-block;
  margin: 0 auto;
  text-transform: uppercase;  
  padding: 5px 20px;
}
 
.cta-btn {
  border: 0 none;
  background-color: transparent;
  padding: 0;
  padding: 10px;
  min-width: 160px;
  display: inline-block;
  margin: 0 auto;
  text-decoration: none;
  text-align: center;
  margin-top: 50px;
  text-transform: uppercase;

  @include rwd(md) {
    margin: 0 auto;
  }
}

.cta-white {
  border: 1px solid $white;
  color: $txt-white;

  &:hover {
    background-color: $white;
    color: $black;
  }
  &.cta-inverse {
    background-color: $white;
    border: 1px solid $white;
    color: $black;

    &:hover {
      background-color: transparent;
      color: $txt-white;
    }
  }
}

.cta-brown {
  border: 1px solid $brown;
  color: $txt-white;

  &:hover {
    background-color: $brown;
    color: $white;
  }
  &.cta-inverse {
    background-color: $brown;
    border: 1px solid $brown;
    color: $white;

    &:hover {
      background-color: transparent;
      color: $txt-brown;
    }
  }
}

.cta-down {
  text-decoration: none;
  position: relative;
  text-transform: uppercase;

  &::after{
    content: "<";
    display: block;
    transform: rotate(-90deg);
  }
}

.cta-back {
  text-decoration: none;
  position: relative;
  color: $txt-white;
  display: flex;
  align-items: center;

  &::before{
    content: "<";
    display: block;
    border: 1px solid $brown;
    padding: 5px 9px;
    border-radius: 50%;
    margin-right: 10px;
    color: $white;
  }
}

.bg-black {
  background-color: $bg-black;
}

.bg-black-blue {
  background-color: $bg-black-blue;
}

.bg-black-darkblue {
  background-color: $bg-black-darkblue;
}

.bg-white {
  background-color: $bg-white;
}

.bg-grey {
  background-color: $bg-grey;
}

.bg-brown {
  background-color: $bg-brown;
}

button {
  #configuratorContainer {
    button {
      border: 0;
      background-color: transparent;
      color: inherit;
    }
  }
}

.slick-dots li button::before {
  opacity: 0 !important;
}
