.box-text-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  text-align: center;
  flex-direction: column;

  @include rwd(md) {
    margin: 0 20px;
  }

  h3 {
    width: 100%;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  
  .card {
    max-width: $max-width;
    margin: 50px auto;
    padding: 15px;

    @include rwd(md-up) {
      padding: 30px;
      width: 81%;
    }
  }

  p {
    font-size: 1.125rem;
    margin-bottom: 25px; 

    @include rwd(md-up) {
      font-size: 1.2rem;
    }
  }

  .cta-btn {
    margin-top: 30px;
  }
}
