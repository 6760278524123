footer {
  display: flex;
  flex-wrap: wrap;
  max-width: 1920px;
  color: $white;
  padding: 80px 30px 60px 30px;
  font-size: 12px;
  line-height: 24px;
  margin: 0 auto;

  @include rwd(xmd-up) {
    text-align: left;
  }

  @include rwd(xmd) {
    text-align: left;
  }

  p {
    font-size: 12px;
    line-height: 26px;
  }

  .footer-col {
    width: 100%;
    margin-bottom: 50px;

    @include rwd(xmd-up) {
      width: 25%; 
      margin-bottom: 0;
    }

    &.mobile-last {
      @include rwd(xmd) {
        order: 4;
      }
    }

    .logo {
      width: 50%;
      max-width: 160px;

    }
    &.footer-first-col {
      position: relative;
      @include rwd(xmd-up) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .social {
        display: flex;

        @include rwd(xmd) {
          align-items: center;
          justify-content: flex-end;
          margin-top: -41px;
        }

        li {
          margin-right: 15px;

          @include rwd(xmd) {
            margin-right: 8px;
          }
        }

        img {
          width: 20px;
          height: auto;
        }
      }
    }
  }

  ul:not(.social) {
    a {
      padding: 5px 0;
      display: block;
      font-size: 12px;
    }
  }

  a {
    color: $txt-white;
    font-size: 12px;
  }

  .footer-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    display: block;
  }

  .text-mini {
    font-size: 10px;
  }

  .footer__newsletter {
    .form-control {
      height: 40px;
      background-color: #ffffff;
      padding-left: 10px;
      border: 0;
      margin: 0;
      border-bottom: 1px solid #fff;
      width: 100%;
    }

    .label-newsletter-promo {
      display: none;
    }

    a {
      text-decoration: underline;
    }

    .footer__submit {
      background: transparent;
      border: 0;
      margin: 0;
      color: $white;
      border-bottom: 1px solid #fff;
      padding: 5px;
    }

    .form-group {

      .input-group{
        position: relative;
        display: -ms-flexbox;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
      }

      .newsletterSign{
        flex-grow: 1;
        position: relative;
        width: 100%;
        max-width: 400px;
        .newsletter-input{
          display: flex;
          .submit-loader{
            right:10px;
            top:5px;
          }
        }
      }

      .footer__submit {
        color: $white;
        border: 0 solid $grey;
        border-bottom: 1px solid $grey;
        padding: 0 10px;
        background: $grey;
        font-size: 8px;
        text-indent: -300%;
        position: relative;
        background-image: url("../images/icons/arrow-right-white.svg"); 
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;


        &:hover {
          border-bottom: none; 
        }
      }

      &.loading {

        input[type="submit"] {
          color: $black;
        }

        .submit-loader,
        .submit-loader:after {
          border-radius: 50%;
          width: 24px;
          height: 24px;
        }

        .submit-loader {
          margin: auto;
          font-size: 10px;
          position: absolute;
          top: calc(50% - #{24px / 2});
          right: 55px;
          text-indent: -9999em;
          border-top: .3em solid rgba(255, 255, 255, 0.2);
          border-right: .3em solid rgba(255, 255, 255, 0.2);
          border-bottom: .3em solid rgba(255, 255, 255, 0.2);
          border-left: .3em solid #ffffff;
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
        }

        @-webkit-keyframes load8 {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes load8 {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }

    label[for="subscribe"] {
      font-family: 'Mulish', sans-serif;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 2.38px;
      line-height: 49px;
      text-transform: uppercase;
    }

    .positive, .negative, .subscriberExist {
      display: none;
    }

    .negative {
      color: $red;
    }

    .privacy-policy {
      .is-invalid {
        + .form-check-label,
        + .form-check-label a{
          color: $red;
        }
      }
    }
  }

  .footer__newsletter__feedback{
    text-align: left;
    width: 100%;

    .positive {
      font-weight: bold;
    }
  }

  .positive, .negative, .subscriberExist {
    display: none;
  }

  .negative {
    color: $red;
  }


}
