.box-tabs {
  max-width: 900px;
  padding: 0px 0 40px 0;
  width: 100%;
  margin: 0 auto;

  @include rwd(md) {
    padding: 0;
  }

  .tab-pane{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 20px;
    display: none;

    &.fade {
      transition: opacity .5s linear;
    }

    &.fade:not(.show) {
      opacity: 0;
    }

    &.active{
      display: flex; 
    } 

    button {
      text-transform: uppercase;
      font-size:14px;
      width: 123px;
      position: relative;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include rwd(md) {
        width: 33%;
        
      }
        

      &.attivo-no,
      &.attivo-si {
        
        padding: 10px;
        margin: 5px;

        @include rwd(md) {
          margin: 0;
        }
      }

      &.attivo-no {
        opacity: 0.5;
        pointer-events: none;
      }

      &.attivo-si {
        cursor: pointer;
      }

      img {
        width: 95px;
        margin-bottom: 10px;

        @include rwd(md) {
          width: 62px;
        }
      }

      .cta-icon {
        font-size: 10px;
        padding-top: 10px;
        display: block;
        //position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
      text-align: center;
      }
    }

    u {
      color: #fff;
      background-color: $brown;
      display: block;
      padding: 1px 4px;
      text-align: center;
      border-radius: 4px;
    }

    .modal-icon {
      margin: 0 auto;
      margin-bottom: 40px;

      img {
        min-width: 62px;
        max-width: 100px;
      }

      &-title { 
        margin-top: 10px;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;

        u {
          background: none;
          color: $txt-brown;
          font-weight: bold;
         
        }
        
      }
     
    }
  }

  .modal {
    text-align: center;
  }

  .nav-tabs{
    position:relative;
    display: flex;
    justify-content: space-around;

    @include rwd(md) {
      padding: 0 20px;
    }

    &.hide {
      display: none;
    }
  }
  
  .nav-tabs .nav-item {
    width: 100%;
    border-bottom: .5px solid #727272;

    a{
      position:relative;
      opacity: 0.5;
      display: block;
      width: 100%;
      padding: 5px 0;
      text-align: center;
      
  
      &.active {
        opacity: 1;
      }
    }
  } 

  .slider-tabs{
    display:inline-block;
    width:30px;
    height:2px;
    border-radius:3px;
    background-color:$white;
    position:absolute;
    z-index:9;
    bottom:0;
    transition:all .4s linear;
  }

  .nav-link {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
}
